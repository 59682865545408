<template>
	<div class="flex flex-col h-full">
		<div class="flex-auto overflow-scroll">
			<h3 class="text-2xl font-bold pt-4 px-4">      	
				Proveedores
			</h3>			

			<div v-if="proveedores && proveedores.length > 0" class="sm:hidden w-full text-left px-4 pb-4 ">	
				<div class="border boder-gray-300 rounded p-2 mt-4" v-for="proveedor in proveedores" v-bind:key="proveedor.id">
					<div class="flex flex-row justify-between items-center">
						<td class="py-3 px-6">							
							<router-link :to='"/admin/proveedor/" + proveedor.id'>{{proveedor.nombre}}</router-link>
						</td>	
						<button class="rounded bg-primary hover:bg-primary-dark text-white font-bold p-2 px-4 "  @click="copyLink(proveedor)">
							Copiar
						</button>
					</div>
				</div>
			</div>

			<div v-if="proveedores && proveedores.length > 0" class="hidden sm:block w-full text-left p-4 ">
				<div class="border border-b-0 boder-gray-300 rounded bg-white">
					<div class="overflow-scroll">
						<table class="w-full text-sm text-left ">
							<thead class="text-s border-b border-b-gray-200">						
								<th scope="col" class="py-3 px-6">
									Nombre
								</th>
								<th scope="col" class="py-3 px-6">
									Pricing
								</th>	
								<th scope="col" class="py-3 px-6">
									Tarimas
								</th>	
								<th scope="col" class="py-3 px-6">
									Enlace
								</th>												
							</thead>
							<tbody>
								<tr class="text-s border-b" v-for="proveedor in proveedores" v-bind:key="proveedor.id">			
									<td class="py-3 px-6 underline">							
										<router-link :to='"/admin/proveedor/" + proveedor.id'>{{proveedor.nombre}}</router-link>
									</td>
									<td class="py-3 px-6">							
										{{proveedor.pricing.nombre}}
									</td>
									<td class="py-3 px-6">							
										{{proveedor.tarimas}}
									</td>
									<td class="py-3 px-6">
										<button class="w-full rounded mr-4 bg-primary hover:bg-primary-dark text-white font-bold p-2 px-4" @click="copyLink(proveedor)">
											Copiar
										</button>
									</td>	
								</tr>              
							</tbody>
						</table>
					</div>
				</div>
			</div>
			
		</div>
		<div class="p-4 flex-none border-t border-t-gray-300">
			<router-link :to='"/admin/proveedor"'>
				<button class="w-full bg-primary hover:bg-primary-dark rounded text-white font-bold py-2 px-4">
					Agregar proveedor
				</button>
			</router-link>
		</div>
	</div>
</template>

<script>	
	import { onMounted, ref } from "vue"
	import { utils } from './utils/utils.js'
	import { useToast } from 'vue-toast-notification';
	
	export default {
		name: 'Admin proveedores',
		setup() {
			const proveedores = ref(null)

			onMounted( async () => {
				proveedores.value = await utils.getProveedores()
			})

			function copyLink(proveedor) {		
				navigator.clipboard.writeText(`${window.location.host}/proveedor/${proveedor.id}`)
				.then( () => {
					useToast().success('Enlace copiado', { position: "top-right" });
				})
				.catch( () => {
					useToast().error('Error el copiar el enlace', { position: "top-right"});
				})			
			}

			return {      				
				proveedores,
				copyLink			
			}
		},
	}
</script> 