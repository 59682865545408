<template>
	<div class="flex flex-col h-full">
		<div class="flex-auto overflow-scroll">
			<h3 class="text-2xl font-bold pt-4 px-4">
				Orden de compra
			</h3>						

			<div v-if="clienteProveedores && clienteProveedores.length > 1" class="w-full text-left px-4 mt-4">
				<label class="block text-gray-700 text-sm font-bold mb-2 mt-0" >
					Proveedor
				</label>
				<select v-model="clienteProveedorSeleccionado" id="" class="border border-gray-300 focus:border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
					<option value="" disabled selected>Escoger un proveedor</option>
					<option v-for="clienteProveedor in clienteProveedores" v-bind:key="clienteProveedor.id" :value="clienteProveedor">{{clienteProveedor.proveedor.nombre}}</option>
				</select>
			</div>

			<div class="border boder-gray-300 rounded p-4 bg-white m-4" v-if="clienteProveedorSeleccionado && clienteProveedorSeleccionado.proveedor && clienteProveedorSeleccionado.proveedor.mensaje" >
				{{clienteProveedorSeleccionado.proveedor.mensaje}}

			</div>

			<empty v-if="clienteProveedorSeleccionado && clienteProveedorSeleccionado.proveedor.productos.length == 0" :texto="'Actualmente no hay productos disponibles.'"> </empty>

			<div v-if="clienteProveedorSeleccionado && clienteProveedorSeleccionado.proveedor.productos.length > 2" class="w-full px-4 mt-4">
				<label class="block text-gray-700 text-sm font-bold mb-2" for="nombre">
					Filtrar productos
				</label>
				<input class="appearance-none border order-gray-300 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-gray-500  w-full mb-2" id="nombre" type="search" placeholder="Nombre" v-model="filter">						
			</div>

			<div class="sm:hidden w-full text-left " v-if="filteredProductos.length > 0" >
				<div class=" w-full text-left px-4" >
					<div class="border boder-gray-300 rounded bg-white p-2 mt-4 cursor-pointer" v-for="producto in filteredProductos.sort((a,b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0))" v-bind:key="producto.nombre">
						<div class="grid grid-cols-4 gap-4">
							<div v-if="producto.foto" class="w-full col-span-1">
								<div class="rounded overflow-hidden">
									<img :src="producto.foto" class="">
								</div>
							</div>
							<div :class="producto.foto ? 'col-span-3' : 'col-span-4'">

								<div class="flex flex-col justify-between h-full">
									<div class="flex flex-col">
										<div class="grid grid-cols-4">
											<div class="col-span-2">
												<div class="flex items-center">
													{{producto.nombre}}
												</div>
											</div>
											<div v-if="proveedor && producto.producto_precio.length > 0">
												<label class="block text-gray-700 text-sm font-bold" for="nombre">
													Precio
												</label>
												<select v-model="producto.precio_override" class="w-full col-span-3 border border-gray-300 focus:border-gray-500 border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline whitespace-nowrap">							
													<option :value="producto.precio">Default ${{producto.precio}}</option>
													<option v-for="precio in producto.producto_precio" v-bind:key="precio.id" :value="precio.precio">{{precio.nombre}} ${{precio.precio}}</option>
												</select>
											</div>

											<div v-else>
												<label class="block text-gray-700 text-sm font-bold" for="nombre">
													Precio
												</label>
												<div class="flex items-center">
													${{producto.precio}}
												</div>
											</div>
											<div>
												<div v-if="proveedor && producto.producto_precio.length > 0 && producto.dupe != false" class="w-full flex flex-row-reverse">
													<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer" @click="duplicateProducto(clienteProveedorSeleccionado, producto)">
														<path stroke-linecap="round" stroke-linejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75" />
													</svg>
												</div>
											</div>										
										</div>
									</div>

									<div class="mt-2 border border-primary rounded overflow-hidden flex justify-between text-white h-8">
										<div class="bg-primary w-16 flex items-center justify-center cursor-pointer" @click="change(producto, -1)">
											-
										</div>
										<div class="text-primary flex-auto flex items-center justify-center">
											{{producto.cantidad_pedido || 0}}
										</div>
										<div class="bg-primary w-16 flex items-center justify-center cursor-pointer" @click="change(producto, 1)">
											+
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="hidden sm:block w-full text-left px-4 mt-4" v-if="clienteProveedorSeleccionado && clienteProveedorSeleccionado.proveedor.productos.length > 0">
				<div class="border boder-gray-300 rounded mt-4 bg-white">
					<div class="overflow-scroll">
						<table class="w-full text-sm text-left ">
							<thead class="text-s border-b border-b-gray-200">
								<th scope="col" class="py-3 px-6 w-4/12">
									Nombre
								</th>
								<th scope="col" class="py-3 px-6 w-3/12">
									Cantidad
								</th>
								<th scope="col" class="py-3 px-6 w-4/12">
									Precio
								</th>
								<th scope="col" class="py-3 px-6 w-1/12">
									
								</th>
							</thead>
							<tbody>
								<tr class="text-s bg-white border-b" v-for="producto in filteredProductos" v-bind:key="producto.nombre">
									<td class="py-3 px-6 whitespace-nowrap">
										<div class="flex items-center">
											<div v-if="producto.foto" class="rounded overflow-hidden w-16 mr-4">
												<img :src="producto.foto" class="">
											</div>
											{{producto.nombre}}
										</div>
									</td>
									<td class="py-3 px-6">
										<input class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:border-gray-500 focus:outline-none focus:shadow-outline" id="nombre" type="number" placeholder="0" min="0" :max="(clienteProveedorSeleccionado.proveedor.standalone) ? 9999999 : producto.cantidad" v-model="producto.cantidad_pedido">
									</td>								
									<td v-if="proveedor && producto.producto_precio.length > 0" class="py-3 px-6">
										<select v-model="producto.precio_override" class="w-full col-span-3 border border-gray-300 focus:border-gray-500 border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline whitespace-nowrap">	
											<option :value="producto.precio">Default ${{producto.precio}}</option>
											<option v-for="precio in producto.producto_precio" v-bind:key="precio.id" :value="precio.precio">{{precio.nombre}} ${{precio.precio}}</option>
										</select>
									</td>
									<td v-else class="py-3 px-6">							
										${{producto.precio}}				
									</td>
									<td>
										<div v-if="proveedor && producto.producto_precio.length > 0 && producto.dupe != false" class="w-full flex flex-row-reverse pr-4">
											<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer" @click="duplicateProducto(clienteProveedorSeleccionado, producto)">
												<path stroke-linecap="round" stroke-linejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75" />
											</svg>
										</div>
									</td>
								</tr>
							</tbody>
							<tfoot class="text-s text-gray-700">
								<tr>
									<th scope="col" class="py-3 px-6">
										Total
									</th>

									<th scope="col" class="py-3 px-6">

									</th>
									<th scope="col" class="py-3 px-6">
										${{total}}
									</th>
								</tr>
							</tfoot>
						</table>
					</div>
				</div>				
			</div>		

			<div class="px-4 pb-4 mt-4">
				<div v-if="proveedor">
					<label class="block text-gray-700 text-sm font-bold mb-2" >
						Cliente
					</label>
					<select v-model="cliente" v-if="clientes && clientes.length > 0" id="countries" class="border border-gray-300 focus:border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
						<option selected disabled value="">Escoger un cliente</option>
						<option v-for="cliente in clientes" v-bind:key="cliente.id" :value="cliente">{{cliente.nombre}}</option>			
					</select>

					<div class="mt-4" v-if="clienteProveedorSeleccionado && !clienteProveedorSeleccionado.proveedor.standalone">
						<label class="block text-gray-700 text-sm font-bold mb-2" for="nombre">
							Tipo de orden
						</label>

						<select v-model="envioType" @change="checkRequiereEntrega()" class="border border-gray-300 focus:border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
							<option :value="'entrega'">Entrega</option>	
							<option :value="'retiro'">Retiro</option>	
						</select>
					</div>

					<div class="mt-4" v-if="clienteProveedorSeleccionado && !clienteProveedorSeleccionado.proveedor.standalone">
						<label class="block text-gray-700 text-sm font-bold mb-2" for="nombre">
							Dirección de entrega
						</label>

						<select v-model="direccion" :disabled="envioType == 'retiro'" @change="checkRequiereEntrega()" class="disabled:opacity-50 border border-gray-300 focus:border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
							<option value="">Dirección de cliente</option>	
							<option v-for="direccion in direcciones" v-bind:key="direccion.id" :value="direccion">{{direccion.nombre}}</option>	
						</select>
					</div>

					<div class="mt-4" v-if="clienteProveedorSeleccionado && !clienteProveedorSeleccionado.proveedor.standalone">
						<label class="block text-gray-700 text-sm font-bold mb-2" for="nombre">
							Requiere entrega
						</label>

						<select v-model="requiereEntrega" :disabled="envioType == 'retiro'" class="enabled:opacity-100 disabled:opacity-50 border border-gray-300 focus:border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
							<option :value="true">Si</option>	
							<option :value="false">No</option>	
						</select>
					</div>

					<div class="mt-4" >
						<label class="block text-gray-700 text-sm font-bold mb-2" for="nombre">
							Requiere factura
						</label>

						<select v-model="requiereFactura" class="border border-gray-300 focus:border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
							<option :value="true">Si</option>	
							<option :value="false">No</option>	
						</select>
					</div>

					<div v-if="clienteProveedorSeleccionado.proveedor.id == 8" class="mt-4" >
						<label class="block text-gray-700 text-sm font-bold mb-2" for="nombre">
							Tipo de entrega
						</label>

						<select v-model="deliveryType" class="border border-gray-300 focus:border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
							<option :value="false">PickPack</option>	
							<option :value="true">Externo</option>	
						</select>
					</div>
				</div>
				<div v-if="clienteProveedorSeleccionado" class="mt-4">
					<label class="block text-gray-700 text-sm font-bold mb-2" for="nombre">
						Instrucciones especiales
					</label>

					<textarea class="appearance-none focus:border-gray-500 border rounded w-full py-2 pb-0 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" name="Instrucciones" cols="40" rows="3" v-model="instrucciones"></textarea>
				</div>
			</div>
		</div>

		<div class="p-4 flex-none border-t border-t-gray-300" v-if="clienteProveedorSeleccionado && clienteProveedorSeleccionado.proveedor.productos.length > 0">
			<button @click="execute" :disabled="clienteProveedorSeleccionado == ''" class="w-full bg-primary hover:bg-primary-dark rounded text-white font-bold py-2 px-4">
				Crear orden
			</button>
		</div>
	</div>
</template>

<script>
	import { useRoute, useRouter } from 'vue-router'
	import { onMounted, ref, computed } from "vue"
	import { useToast } from 'vue-toast-notification';
	import axios from "axios"
	import { useLoading } from 'vue-loading-overlay'
	import { utils } from './utils/utils.js'
	import { store } from '../store.js'

	export default {
		name: 'Order',
		setup() {
			const route = useRoute()
			const router = useRouter()
			const cliente = ref("")
			const clientes = ref([])			
			const clienteProveedores = ref(null)
			const clienteProveedorSeleccionado = ref("")
			const instrucciones = ref("")				
			const filter = ref(null)
			const proveedor = ref(false)
			const requiereEntrega = ref(true)
			const requiereFactura = ref(true)
			const envioType = ref('entrega')
			const direcciones = ref([])
			const direccion = ref("")
			const deliveryType = ref("false")

			const proveedorObject = ref({})

			const loading = useLoading({
				color: "#428b74",
				loader: "spinner"
			});

			let loader;			

			async function mandarConfirmacion(data) {
				try {
					axios
					.post('/api/mandar_confirmacion', {
						orden_id: data.pedido.id
					})
					.finally( () => {
						return
					})
				}
				catch (e) {
					console.log(e)
					return data
				}
			}

			async function execute() {
				let data = await crearPedido()
				console.log("Data:", data)

				if (data && data.pedido) {	
					mandarConfirmacion(data)
					.then( () => {
						useToast().success('Orden creado', { position: "top-right" });
						if (proveedor.value) {
							router.push(`/proveedor/${proveedor.value}`)
						} else {
							router.push(`/cliente/${cliente.value.id}/orden/${data.pedido.id}`)	
						}
						
					})
					.catch( (error) => {
						console.log(error)
						useToast().error('Error al crear orden', { position: "top-right" });
					})
				}
			}

			async function crearPedido() {
				if (!cliente.value) {
					return useToast().warning("Favor de escoger un cliente", { position: "top-right" });
				}

				let stock_insuficiente = clienteProveedorSeleccionado.value.proveedor.productos.filter( p => { return p.cantidad_pedido > 0 && p.cantidad_pedido > p.cantidad})

				if (!clienteProveedorSeleccionado.value.proveedor.standalone) {
					if (stock_insuficiente.length > 0) {
						useToast().warning(`Solo hay ${stock_insuficiente[0].cantidad} de ${stock_insuficiente[0].nombre} disponible`, { position: "top-right" });
						return
					}
				}

				let total = clienteProveedorSeleccionado.value.proveedor.productos.map( p => { return p.cantidad_pedido || 0}).reduce((a, b) => a + b)

				if (!total || total == 0 ) {
					useToast().warning("Debes de tener mínimo un producto en tu orden de compra", { position: "top-right" });
					return
				}

				if (cliente.value.id != 16 && total < clienteProveedorSeleccionado.value.proveedor.minimo) {
					useToast().warning(`El pedido mínimo para ${clienteProveedorSeleccionado.value.proveedor.nombre} es ${clienteProveedorSeleccionado.value.proveedor.minimo}`, { position: "top-right" });
					return
				}

				loader = loading.show()

				let pedido = {
					"cliente": cliente.value.id,
					"proveedor": clienteProveedorSeleccionado.value.proveedor.id,
					"instrucciones": instrucciones.value,
					"require_entrega": requiereEntrega.value,
					"require_factura": requiereFactura.value,
					"envio_type": envioType.value,
					"datos_entrega": {
						"direccion": {
							"calle": direccion.value.direccion_calle || cliente.value.direccion_calle,
							"interior": direccion.value.direccion_interior || (cliente.value.direccion_interior || ""),
							"exterior": direccion.value.direccion_exterior || cliente.value.direccion_exterior,
							"codigo": direccion.value.direccion_codigo ||  cliente.value.direccion_codigo,
							"colonia": direccion.value.direccion_colonia ||  cliente.value.direccion_colonia,
							"estado": direccion.value.direccion_estado ||  cliente.value.direccion_estado
						},
						"horario": direccion.value.horario || cliente.value.horario,
						"contacto": {
							"nombre": direccion.value.contacto_nombre || cliente.value.contacto_nombre,
							"telefono": direccion.value.contacto_telefono || cliente.value.contacto_telefono
						},
						"recipiente": direccion.value.nombre || cliente.value.nombre
					},
					"standalone": deliveryType.value
				}

				if (clienteProveedorSeleccionado.value.proveedor.standalone) {
					pedido.standalone = true
				}

				if (clienteProveedorSeleccionado.value.require_aprobacion) {
					pedido.require_aprobacion = true
				} else {
					pedido.require_aprobacion = false
				}

				let line_items = clienteProveedorSeleccionado.value.proveedor.productos.filter( p => { return p.cantidad_pedido > 0}).map( (p) => {
					let data = {
						"pedido": pedido.id,
						"producto": p.id,
						"cantidad": p.cantidad_pedido,
						"precio": p.precio_override ? p.precio_override : p.precio,
						"proveedor": p.proveedor						
					}

					if (p.precioNormal) {
						data.precioNormal = p.precioNormal
					}

					return data
				})

				try {
					let response = await axios.post('/api/create_orden', {
						pedido: pedido,
						line_items: line_items
					})

					console.log("response", response)

					loader.hide()
					if (response.data) {	
						return response.data
					} else {
						throw alert("Pedido no creado")
					}
				} catch (e) {
					loader.hide()
					alert("Hubo un error, favor de intentarlo otra vez.")
				}
				
			}

			const total = computed( () => {
				let productoTotal = 0

				if (clienteProveedorSeleccionado.value == null || clienteProveedorSeleccionado.value == "") {
					return productoTotal
				} else {
					//How to handle if the inventery is 0?
					if (clienteProveedorSeleccionado.value.proveedor.productos.length == 0) {
						return productoTotal
					}

					productoTotal = clienteProveedorSeleccionado.value.proveedor.productos.map( producto => {
						return (producto.precio_override ? producto.precio_override * producto.cantidad_pedido : producto.precio * producto.cantidad_pedido) || 0
					}).reduce((a, b) => a + b)
				}

				return productoTotal
			})

			function change(producto, cantidad) {
				if (!producto.cantidad_pedido) {
					producto.cantidad_pedido = 0
				}

				if (clienteProveedorSeleccionado.value.proveedor.standalone) {
					if (producto.cantidad_pedido + cantidad >= 0) {
						producto.cantidad_pedido = producto.cantidad_pedido + cantidad
					}
				} else {
					if (producto.cantidad_pedido + cantidad >= 0 && producto.cantidad_pedido + cantidad <= producto.cantidad) {
						producto.cantidad_pedido = producto.cantidad_pedido + cantidad
					}
				}
				
				
			}


			const filteredProductos = computed( () => {
				if (!clienteProveedorSeleccionado.value) {
					return []
				}

				return clienteProveedorSeleccionado.value.proveedor.productos.filter(p => {
					if (!filter.value) {
						return true
					}					

					return p.nombre.toLowerCase().includes(filter.value.toLowerCase())
				})
			})

			function checkRequiereEntrega() {
				if (envioType.value == 'retiro') {
					requiereEntrega.value = true
					direccion.value = ""
				}
			}

			onMounted( async () => {
				if (route.params.proveedor_id) {
					proveedor.value = route.params.proveedor_id
				}	

				if (proveedor.value) {
					let po = await utils.getProveedor(route.params.proveedor_id)
					proveedorObject.value = po
					
					console.log('proveedorObject', proveedorObject.value)

					utils.getDirecciones(route.params.proveedor_id)
					.then( data => {
						direcciones.value = data 
					})

					utils.getClientes(proveedor.value)
					.then( data => {
						clientes.value = data						
						if (route.query.cliente) {
							cliente.value = clientes.value.find( c => c.id == route.query.cliente)
						}
					})			

					let productos

					if (proveedor.value != 21) {
						productos = await utils.getProductosPorProveedor(proveedor.value)

						productos = productos.map( p => {
							if (p.producto_precio.length > 0) {
								p.precio_override = p.precio
							}

							return p
						})
						

						console.log(productos)
					} else {
						productos = await utils.getProductosTodoChido() 
					}					
					
					clienteProveedores.value = [{
						proveedor: {
							id: proveedor.value,
							standalone: proveedorObject.value.standalone,
							productos: productos.filter(p => !p.archivado)
						}
					}]					

					clienteProveedorSeleccionado.value = clienteProveedores.value[0]		
				} else {
					utils.getCliente(route.params.cliente_id).then( data => {
						cliente.value = data				
						store.setNombre(data.nombre)
					})

					axios.get(`/api/orden/agregar_orden?cliente_id=${route.params.cliente_id}`)
					.then( data => {					
						clienteProveedores.value = data.data

						if (clienteProveedores.value.length == 1) {
							clienteProveedorSeleccionado.value = clienteProveedores.value[0]
						}				
					})
					.catch( error => {
						console.log(error)
					})
				}
			})

			function duplicateProducto(clienteProveedor, producto) {
				let dupe = window.structuredClone(producto)
				dupe.dupe = false
				clienteProveedor.proveedor.productos.push(dupe)

			}

			return {
				cliente,
				clientes,
				clienteProveedores,
				clienteProveedorSeleccionado,
				instrucciones,
				execute,
				total,
				change,
				filter,
				filteredProductos,
				proveedor,				
				requiereEntrega,
				requiereFactura,
				envioType,
				checkRequiereEntrega,
				direccion,
				direcciones,
				proveedorObject,
				duplicateProducto,
				deliveryType
			}
		},
	}
</script>
