<template>
	<div v-if="user && user.type == 'proveedor' && user.passcode && !auth" class="absolute inset-0 w-full h-full">
		<div class="absolute inset-0 w-full h-full bg-black bg-opacity-50"></div>
		<div class="absolute w-96 py-8 rounded-lg bg-white top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center px-4 space-y-4">

			<div class="text-center">Passcode requerido</div>	
			<input class="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-full" id="nombre" type="text" placeholder="Passcode" v-model="passcode" v-on:keyup.enter="checkPasscode(passcode)">
			<button class="w-full bg-primary hover:bg-primary-dark rounded text-white font-bold py-2 px-4" @click="checkPasscode(passcode)">
				Entregar
			</button>
		</div>
	</div>	

	<div class="flex flex-col sm:flex-row h-full overflow-hidden">
		<div class="hidden sm:block bg-white">
			<div v-if="showNav" class='w-64 h-full p-4 '>
				<router-link :to='home'><p class="self-center text-xl font-semibold whitespace-nowrap dark:text-white">PickPack</p></router-link>
				<div class="pt-8">
					<div v-for="link in links" v-bind:key="link.title" >			
						<router-link v-if="!(link.title == 'Catalogo'  || link.title == 'Alta' || link.title == 'Entregas') "  :to='link.link' :class="{ 'font-bold': link.bold.includes(route.name) }" >
							<div class="flex">
								<g v-html="link.icon"></g>
								<p class="pl-2 cursor-pointer pb-4">{{link.title}}</p>
							</div>
						</router-link>

						<a :href='link.link' v-if="link.title == 'Catalogo' || link.title == 'Alta' || link.title == 'Entregas' "  target="_blank">
							<div class="flex">								
								<g v-html="link.icon"></g>
								<p class="pl-2 cursor-pointer pb-4">{{link.title}}</p>
							</div>
						</a>
					</div>
				</div>
			</div>
		</div>

		<div class="sm:hidden block"> 
			<div v-if="showNav" class='w-full h-full bg-white'>			
				<div class="p-2 px-4 z-10">
					<div class="flex justify-between">
						<router-link :to='home'><p class="self-center text-xl font-semibold whitespace-nowrap dark:text-white">PickPack</p></router-link>
						<div class="cursor-pointer" @click="showMenu = !showMenu">
							<svg v-if="!showMenu" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
								<path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
							</svg>          
							<svg v-if="showMenu" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
								<path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
							</svg>

						</div>
					</div>
				</div>
				<div v-if="showMenu" class="top-0 absolute w-full h-full z-20 overflow-hidden">
					<div class="flex justify-between bg-white pb-4 pt-2 px-4">
						<router-link :to='home'><p class="self-center text-xl font-semibold whitespace-nowrap dark:text-white">PickPack</p></router-link>
						<div class="cursor-pointer" @click="showMenu = !showMenu">
							<svg v-if="!showMenu" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
								<path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
							</svg>          
							<svg v-if="showMenu" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
								<path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
							</svg>

						</div>
					</div>
					<div v-for="link in links" v-bind:key="link.title" class="bg-white px-4">					
						<router-link v-if="!(link.title == 'Catalogo'  || link.title == 'Alta') " :to='link.link' :class="{ 'font-bold': link.bold.includes(route.name) }" @click="showMenu = !showMenu" >
							<div class="flex">
								<g v-html="link.icon"></g>
								<p class="pl-2 cursor-pointer pb-4">{{link.title}}</p>
							</div>
						</router-link>

						<a :href='link.link' v-if="link.title == 'Catalogo' || link.title == 'Alta' " target="_blank">
							<div class="flex">
								<g v-html="link.icon"></g>
								<p class="pl-2 cursor-pointer pb-4">{{link.title}}</p>
							</div>
						</a>
					</div>
					<div class="relative w-full h-full bg-black opacity-60" @click="showMenu = !showMenu" >
					</div>
				</div>

			</div>
		</div>

		<div class="w-full h-full overflow-hidden">
			<router-view></router-view>
		</div>
	</div>
</template>

<script>
	import { computed, ref, watch } from 'vue'
	import { useRoute } from 'vue-router'
	import { store } from './store.js'
	import { utils } from './components/utils/utils.js'

	export default {
		name: 'App',
		setup() {
			const showMenu = ref(false)
			const route = useRoute() 	

			const passcode = ref("")

			watch( () => route.params,
				async () => {
					setUser()
					setManifest()
				}
				) 

			function setManifest() {
				let startUrl = "."
				console.log(window, route.params.proveedor_id)

				if (route.params.proveedor_id) {
					startUrl = `${window.location.origin}/proveedor/${route.params.proveedor_id}`
				} else if (route.params.cliente_id) {
					startUrl = `${window.location.origin}/cliente/${route.params.cliente_id}`
				} 

				var manifest = {
					"name": "PickPack",
					"short_name": "PickPack",
					"start_url": startUrl,
					"display": "standalone",
					"background_color": "#fff",
					"description": "Gestión de pedidos y servicio de logística",
					"icons": [{ 
						"src": `${window.location.origin}/apple-icon-144x144.png`,
						"sizes": "144x144",
						"type": "image/png",
						"density": "1.0"
					}],
					"screenshots": [
					{
						"src": `${window.location.origin}/screenshot.png`,
						"type": "image/png",
						"sizes": "1179x2556",
						"form_factor": "narrow"
					}
					]
				}

				const stringManifest = JSON.stringify(manifest);
				const blob = new Blob([stringManifest], {type: 'application/json'});
				const manifestURL = URL.createObjectURL(blob);
				document.querySelector('#my-manifest-placeholder').setAttribute('href', manifestURL);

				var link = document.createElement('Link');
				link.rel = "manifest";
				link.setAttribute('href', 'data:application/json;charset=8' + stringManifest)
			}

			function setUser() {						
				if (route.params.proveedor_id) {
					if (!store.user || store.type != 'proveedor') {
						utils.getProveedor(route.params.proveedor_id)
						.then( data => {               
							data.type = 'proveedor'
							store.setUser(data)                      
						})  
					}    
				} else if (route.params.cliente_id) {
					if (!store.user || store.type != 'cliente') {
						utils.getCliente(route.params.cliente_id)
						.then( data => {     
							store.setUser(data)                      
						})    
					}
				} 
			}

			const showNav = computed( () => {
				if (route.path == "/") {
					return false
				} else if (route.name == "Admin_Remision") {
					return false
				} else if (route.name == "Cliente_Alta") {
					return false
				} else if (route.name == "Alta") {
					return false
				} else if (route.name == "Shop") {
					return false
				} else if (route.name == "Pedido") {
					return false
				} else if (route.name == "Proveedor_Entregas") {
					return false
				} else if (route.name == "Proveedor_Catalogo") {
					return false
				} else {
					return true
				}
			})

			const links = computed( () => { 			
				if(route.path.includes('admin')) {
					return [{
						title: "Inbox",
						link: `/admin`,
						bold: ["Admin_Home"],
						icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M2.25 13.5h3.86a2.25 2.25 0 012.012 1.244l.256.512a2.25 2.25 0 002.013 1.244h3.218a2.25 2.25 0 002.013-1.244l.256-.512a2.25 2.25 0 012.013-1.244h3.859m-19.5.338V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 00-2.15-1.588H6.911a2.25 2.25 0 00-2.15 1.588L2.35 13.177a2.25 2.25 0 00-.1.661z" /></svg>'
					},{
						title: "Ordenes",
						link: `/admin/ordenes`,
						bold: ["Admin_Ordenes", 'Admin_Orden'],
						icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M6.429 9.75L2.25 12l4.179 2.25m0-4.5l5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0l4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0l-5.571 3-5.571-3" /></svg>'
					},{
						title: "Proveedores",
						link: `/admin/proveedores`,
						bold: ["Admin_Proveedores", "Admin_Agregar_Proveedor"],
						icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" /></svg>'
					},{
						title: "Inventarios",
						link: `/admin/inventario`,
						bold: ["Admin_Inventario"],
						icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M15.666 3.888A2.25 2.25 0 0013.5 2.25h-3c-1.03 0-1.9.693-2.166 1.638m7.332 0c.055.194.084.4.084.612v0a.75.75 0 01-.75.75H9a.75.75 0 01-.75-.75v0c0-.212.03-.418.084-.612m7.332 0c.646.049 1.288.11 1.927.184 1.1.128 1.907 1.077 1.907 2.185V19.5a2.25 2.25 0 01-2.25 2.25H6.75A2.25 2.25 0 014.5 19.5V6.257c0-1.108.806-2.057 1.907-2.185a48.208 48.208 0 011.927-.184" /></svg>'
					},{
						title: "Entradas",
						link: `/admin/entradas`,
						bold: ["Admin_Entrada_Agregar", "Admin_Entrada", "Admin_Entradas"],
						icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" /></svg>'
					},{
						title: "Relaciones",
						link: `/admin/proveedor_cliente`,
						bold: ["Admin_Proveedor_Cliente"],
						icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" /></svg>'
					},{
						title: "TodoChido",
						link: `/admin/todochido`,
						bold: ["Admin_TodoChido", "Admin_TodoChido_Producto"],
						icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" /></svg>'
					},{
						title: "Recibos",
						link: `/admin/recibos`,
						bold: ["Admin_Recibos", "Admin_Recibo", "Admin_Corte"],
						icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" /></svg>'
					},{
						title: "Payouts",
						link: `/admin/payouts`,
						bold: ["Admin_Payouts", "Admin_Payout", "Admin_Payout"],
						icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="m9 14.25 6-6m4.5-3.493V21.75l-3.75-1.5-3.75 1.5-3.75-1.5-3.75 1.5V4.757c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0c1.1.128 1.907 1.077 1.907 2.185ZM9.75 9h.008v.008H9.75V9Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm4.125 4.5h.008v.008h-.008V13.5Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" /></svg>'
					},{
						title: "Combinar clientes",
						link: `/admin/combinar_clientes`,
						bold: ["Admin_Combinar_Clientes"],
						icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75" /></svg>'
					},{
						title: "Suscripciones",
						link: `/admin/sub`,
						bold: ["Admin_Sub"],
						icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z" /></svg>'
					}]  
} else if (route.params.proveedor_id) {	
	let proveedor_links = []

	if (!store.user.id) {
		return 
	}

	if (store.user.standalone) {
		proveedor_links = [{
			title: "Inbox",
			link: `/proveedor/${store.user.id}`,
			bold: ["Proveedor"],
			icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M2.25 13.5h3.86a2.25 2.25 0 012.012 1.244l.256.512a2.25 2.25 0 002.013 1.244h3.218a2.25 2.25 0 002.013-1.244l.256-.512a2.25 2.25 0 012.013-1.244h3.859m-19.5.338V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 00-2.15-1.588H6.911a2.25 2.25 0 00-2.15 1.588L2.35 13.177a2.25 2.25 0 00-.1.661z" /></svg>'
		},{
			title: "Ordenes",
			link: `/proveedor/${store.user.id}/ordenes`,
			bold: ["Proveedor_Ordenes", "Proveedor_Orden", "Proveedor_Agregar_Orden"],
			icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M6.429 9.75L2.25 12l4.179 2.25m0-4.5l5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0l4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0l-5.571 3-5.571-3" /></svg>'
		},{
			title: "Clientes",
			link: `/proveedor/${store.user.id}/clientes`,
			bold: ["Proveedor_Clientes", "Proveedor_Cliente", "Proveedor_Editar_Cliente"],
			icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" /></svg>'
		},{
			title: "Productos",
			link: `/proveedor/${store.user.id}/productos`,
			bold: ["Proveedor_Productos", "Proveedor_Agregar_Producto", "Proveedor_Producto", "Proveedor_Agregar_Producto_Precio", "Proveedor_Editar_Producto_Precio"],
			icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" /></svg>'
		},{
			title: "Alta",
			link: `/alta?proveedor_id=${store.user.id}`,
			bold: [],
			icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"/></svg>'
		},{
			title: "Entregas",
			link: `/proveedor/${store.user.id}/entregas`,
			bold: [],
			icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M9 6.75V15m6-6v8.25m.503 3.498 4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 0 0-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0Z" /></svg>'
		}]						
	} else {
		proveedor_links = [{
			title: "Inbox",
			link: `/proveedor/${store.user.id}`,
			bold: ["Proveedor"],
			icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M2.25 13.5h3.86a2.25 2.25 0 012.012 1.244l.256.512a2.25 2.25 0 002.013 1.244h3.218a2.25 2.25 0 002.013-1.244l.256-.512a2.25 2.25 0 012.013-1.244h3.859m-19.5.338V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 00-2.15-1.588H6.911a2.25 2.25 0 00-2.15 1.588L2.35 13.177a2.25 2.25 0 00-.1.661z" /></svg>'
		},{
			title: "Ordenes",
			link: `/proveedor/${store.user.id}/ordenes`,
			bold: ["Proveedor_Ordenes", "Proveedor_Orden", "Proveedor_Agregar_Orden"],
			icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M6.429 9.75L2.25 12l4.179 2.25m0-4.5l5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0l4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0l-5.571 3-5.571-3" /></svg>'
		},{
			title: "Clientes",
			link: `/proveedor/${store.user.id}/clientes`,
			bold: ["Proveedor_Clientes", "Proveedor_Cliente", "Proveedor_Editar_Cliente"],
			icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" /></svg>'
		},{
			title: "Productos",
			link: `/proveedor/${store.user.id}/productos`,
			bold: ["Proveedor_Productos", "Proveedor_Agregar_Producto", "Proveedor_Producto", "Proveedor_Agregar_Producto_Precio", "Proveedor_Editar_Producto_Precio"],
			icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" /></svg>'
		},{
			title: "Direcciones",
			link: `/proveedor/${store.user.id}/direcciones`,
			bold: ["Direcciones", "Editar direccion", "Agregar direccion"],
			icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" /><path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" /></svg>'
		},{
			title: "Entradas",
			link: `/proveedor/${store.user.id}/entradas`,
			bold: ["Proveedor_Entradas", "Proveedor_Entrada"],
			icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" /></svg>'
		},{
			title: "Recibos",
			link: `/proveedor/${store.user.id}/recibos`,
			bold: ["Proveedor_Recibos", "Proveedor_Recibo"],
			icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" /></svg>'
		},{
			title: "Alta",
			link: `/alta?proveedor_id=${store.user.id}`,
			bold: [],
			icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"/></svg>'
		}]
}

if (store.user.ecom) {
	proveedor_links = proveedor_links.concat([{
		title: "Payouts",
		link: `/proveedor/${store.user.id}/payouts`,
		bold: ["Proveedor_Payouts", "Proveedor_Payout"],
		icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="m9 14.25 6-6m4.5-3.493V21.75l-3.75-1.5-3.75 1.5-3.75-1.5-3.75 1.5V4.757c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0c1.1.128 1.907 1.077 1.907 2.185ZM9.75 9h.008v.008H9.75V9Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm4.125 4.5h.008v.008h-.008V13.5Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" /></svg>'
	},{
		title: "Catalogo",
		link: `/proveedor/${store.user.id}/catalogo`,
		bold: ["Proveedor_Catalogo"],
		icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 0 1 0 3.75H5.625a1.875 1.875 0 0 1 0-3.75Z" /></svg>'
	}])
} else {
	proveedor_links = proveedor_links.concat([{
		title: "Catalogo",
		link: `/proveedor/${store.user.id}/catalogo`,
		bold: ["Proveedor_Catalogo"],
		icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 0 1 0 3.75H5.625a1.875 1.875 0 0 1 0-3.75Z" /></svg>'
	}])
}

return proveedor_links
} else if (route.params.cliente_id) {
	setUser();

	return [{
		title: "Ordenes",
		link: `/cliente/${store.user.id}`,
		bold: ["Cliente", "CLiente_Agregar_Orden", "Cliente_Orden"],
		icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M6.429 9.75L2.25 12l4.179 2.25m0-4.5l5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0l4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0l-5.571 3-5.571-3" /></svg>'
	}]  
} else {
	return []
}
})


const user = computed( () => {
	return store.user
})

const auth = computed( () => {
	return store.auth || false
})

const home = computed( () => {	
	if (route.name == "Cliente_Alta") {
		return ""
	} else if (route.name == "Alta") {
		return ""
	}

	else if (route.path.includes('admin')) {
		return "/admin"
	} else if (route.params.proveedor_id) {          
		return `/proveedor/${route.params.proveedor_id}`
	} else if (route.params.cliente_id) {
		return `/cliente/${route.params.cliente_id}`
	} 
	return ""
})

function checkPasscode(passcode) {
	console.log(passcode, user.value.passcode)
	if (passcode == user.value.passcode) {
		store.setAuth(true)
	} else {
		alert('Passcode invalido')
	}

}
return {
	home,          
	showNav,
	route,
	links,
	showMenu,
	user,
	auth,
	passcode,
	checkPasscode
}
},
watch: {
	'$route' (to) {
		document.title = to.meta.title || 'Pick Pack'
	}
}
}
</script>
