<template>
	<div v-if="pedido && showPayment && !pedido.pagado" class="fixed top-0 left-0 w-full h-full z-50 flex items-center justify-center" style="background: rgba(0, 0, 0, 0.5);">
		<payment :pedido="pedido" :handler="paymentHandler" :paymentMethods="paymentMethods"></payment>		
	</div>


	<div class="flex flex-col h-full">
		<div class="flex-auto overflow-scroll">
			<div v-if="pedido">
				<div class="flex flex-row items-center pt-4 px-4 justify-between">
					<div class="flex content-center">
						<div @click="utils.goBack(router, `/cliente/${route.params.cliente_id}`)" class="pr-2 flex items-center cursor-pointer"> 				
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
								<path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
							</svg>
						</div>	
						<h3 class="text-2xl font-bold">
							Orden #{{pedido.id}}
						</h3>
					</div>	
					<status :pedido="pedido" :user="'cliente'"></status>
				</div>

				
				<div class="rounded border bg-white rounded m-4" v-if="pedido && !pedido.cancelado">	
					<div class="flex flex-row items-top pt-4 px-4 justify-between mb-4">
						<h3 class="text-xl font-semibold">
							{{pedido.proveedor.nombre}}
						</h3>

						<h3 class="text-xl font-semibold">
							{{pedido.created_at ? (new Date(pedido.created_at)).toLocaleDateString("en-GB", { timeZone: 'America/Mexico_City' }) : ""}}
						</h3>
					</div>

					<div class="flex flex-row px-4 pt-0 pb-4 overflow-x-scroll w-full" >						
						<div class="basis-3/12 w-full mr-4">
							<label class="block text-gray-400 text-sm font mb-2" for="nombre">
								Factura
							</label>
							<div :class="[pedido.require_factura ? ( pedido.pedido_factura.length > 0 ? 'border border-primary text-primary' : 'border border-yellow-500 text-yellow-500') : 'border text-gray-300 border-gray-300']" class="rounded-full text-center py-1 px-2">
								<p class="whitespace-nowrap">{{ pedido.require_factura ? ( pedido.pedido_factura.length > 0 ? "Facturado" : "Subir factura") : "No requiere"}}</p>
							</div>
						</div>

						<div class="basis-3/12 w-full">
							<label class="block text-gray-400 text-sm font mb-2" for="nombre">
								Entrega
							</label>
							<entregar :pedido="pedido"></entregar>
						</div>

						

						<div class="basis-3/12 w-full ml-4" v-if="pedido.proveedor.payments">
							<label class="block text-gray-400 text-sm font mb-2" for="nombre">
								Pago
							</label>
							<div :class="[pedido.require_factura ? ( pedido.pagado ? 'border border-primary text-primary' : 'border border-yellow-500 text-yellow-500') : 'border text-gray-300 border-gray-300']" class="rounded-full text-center py-1 px-2">
								<p class="whitespace-nowrap">{{ pedido.require_factura ? ( pedido.pagado ? "Pagado" : "Pendiente") : "No requiere"}}</p>
							</div>
						</div>
					</div>

					<div v-if="pedido.instrucciones" class="p-4 pt-0">				
						<label class="block text-gray-400 text-sm font" for="nombre">
							Instrucciones
						</label>
						<div>
							<p class="">{{ pedido.instrucciones }}</p>
						</div>
					</div>

				</div>

				
				<div class="w-full text-left p-4 pt-0">
					<div class="border boder-gray-300 rounded mt-4 bg-white">
						<div class="overflow-scroll">
							<table class="w-full text-sm text-left ">
								<thead class="text-s border-b border-b-gray-200">
									<th scope="col" class="py-3 px-6 w-3/12">
										Producto
									</th>
									<th scope="col" class="py-3 px-6 w-3/12">
										Cantidad
									</th>
									<th scope="col" class="py-3 px-6 w-3/12">
										Precio
									</th>
									<th scope="col" class="py-3 px-6 w-3/12">
										Total
									</th>							
								</thead>
								<tbody>
									<tr class="text-s border-b" v-for="line_item in pedido.line_items" v-bind:key="line_item.id">
										<td class="py-3 px-6 whitespace-nowrap">
											{{line_item.producto.nombre}}
										</td>
										<td class="py-3 px-6">
											{{line_item.cantidad}}
										</td>
										<td class="py-3 px-6">
											${{line_item.precio}}
										</td>
										<td class="py-3 px-6">
											${{line_item.precio * line_item.cantidad}}					
										</td>
									</tr>              
								</tbody>
								<tfoot class="text-s text-gray-700 dark:text-gray-400">								
									<tr v-if="pedido.envio">
										<th scope="col" class="px-6 pt-3 pb-1 font-medium">
											Producto
										</th>
										<th scope="col" class="py-3 px-6">

										</th>
										<th scope="col" class="px-6 pt-3 pb-1 font-medium">

										</th>
										<th scope="col" class="px-6 pt-3 pb-1 font-medium">
											${{pedido.line_items.map( l => {return l.precio * l.cantidad} ).reduce((a, b) => a + b) }}
										</th>
									</tr>
									<tr v-if="pedido.envio">
										<th scope="col" class="px-6 font-medium">
											Envio
										</th>
										<th scope="col" class="py-3 px-6">

										</th>
										<th scope="col" class="px-6 font-medium">

										</th>
										<th scope="col" class="px-6 font-medium">
											${{pedido.envio}}
										</th>
									</tr>
									<tr>
										<th scope="col" class="py-3 px-6">
											Total
										</th>
										<th scope="col" class="py-3 px-6">
											{{pedido.line_items.map( l => {return l.cantidad} ).reduce((a, b) => a + b) }}
										</th>
										<th scope="col" class="py-3 px-6">

										</th>
										<th scope="col" class="py-3 px-6">
											${{pedido.line_items.map( l => {return l.precio * l.cantidad} ).reduce((a, b) => a + b) + (pedido.envio || 0) }}
										</th>
									</tr>
								</tfoot>
							</table>
						</div>
					</div>
				</div>

				<div class="text-left p-4 pt-0 flex flex-row w-full overflow-scroll gap-8">
					<div v-for="(pf, index) in pedido.pedido_factura" v-bind:key="pf.id" class="flex-none w-72 border boder-gray-300 rounded overflow-hidden bg-white">
						<div class="text-sm font-bold py-3 px-6">
							Facutra {{index +1}}
						</div>

						<a :href="pf.url" target="_blank">				
							<embed :src="pf.url" class="w-72 h-full object-cover">				
							</a>
						</div>

						<div v-for="(evidencia, index) in evidencias" v-bind:key="evidencia.envio" class="flex-none w-72 border boder-gray-300 rounded overflow-hidden">	
							<div class="text-sm font-bold py-3 px-6">
								Evidencia {{index +1}}
							</div>
							<a :href="evidencia.evidencia" target="_blank">
								<img :src="evidencia.evidencia" class="w-72 h-full object-cover">
							</a>					
						</div>
					</div>	
				</div>			
			</div>
			<div v-if="pedido && (puedeCancelar || pedido.require_factura && !pedido.pagado && pedido.proveedor.payments)" class="border-t border-t-gray-300 space-y-4 p-4">
				<div v-if="pedido.require_factura && !pedido.pagado && pedido.proveedor.payments" class="flex-none">
					<button class="w-full bg-primary hover:bg-primary-dark rounded text-white font-bold py-2 px-4 " @click="showPaymentModal()">
						Pagar
					</button>
				</div>

				<div v-if="puedeCancelar" class="flex-none">
					<button class="w-full bg-white border-primary border hover:border-primary-dark rounded text-primary font-bold py-2 px-4" @click="cancelarPedido()">
						Cancelar pedido
					</button>
				</div>
			</div>
		</div>
	</template>

	<script>	
		import { utils } from "./utils/utils.js"
		import { useRoute, useRouter } from "vue-router"	
		import { onMounted, ref, computed } from "vue"	
		import { useToast } from 'vue-toast-notification';
		import { useLoading } from 'vue-loading-overlay'
		import axios from "axios"

		export default {
			name: 'Order',
			setup() {						
				const route = useRoute()
				const router = useRouter()
				const pedido = ref(null)
				const cliente = ref({})

				const showPayment = ref(false)

				const paymentMethods = ref(null)

				const loading = useLoading({
					color: "#428b74",
					loader: "spinner"
				});

				const puedeCancelar = computed( () => {
					if (!pedido.value.require_entrega) {
						return false
					}

					if (pedido.value.entregado) {
						return false
					}

					return pedido.value && pedido.value.surtido != true && pedido.value.cancelado != true
				})	

				const evidencias = computed( () => {
					return pedido.value.envios.filter( e => e.status == 'exitoso').flatMap( e => {
						if (!e.evidencia) {
							return []
						}
						return e.evidencia.flatMap( ev => {
							return {
								envio: e.id,
								evidencia: ev
							}
						})
					})
				})

				async function cancelarPedido() {		
					if(confirm("¿Quieres cancelar este pedido?")){	
						let loader = loading.show()
						utils.updatePedido(pedido.value.id, {cancelado: true})
						.then( () => {
							loader.hide()
							useToast().success('Pedido cancelado', { position: "top-right" });
							pedido.value.cancelado = true
						})
						.catch( () => {
							loader.hide()
							alert('Hubo un error, favor de intertarlo otra vez.')
						})					
					}
				}

				onMounted( async () => {
					if (route.query.pago) {
						showPaymentModal()
					}

					utils.getPedido(route.params.orden_id)
					.then( data => {
						pedido.value = data
					})
					.catch( () => {
						alert('Hubo un error, favor de intentarlo otra vez')
					})

					utils.getCliente(route.params.cliente_id)
					.then( data => {
						cliente.value = data
					})
					.catch( () => {
						alert('Hubo un error, favor de intentarlo otra vez')
					})					
				})	

				function showPaymentModal() {				
					if (!paymentMethods.value) {
						let loader = loading.show()
						axios.get(`/api/payment_methods?cliente_id=${route.params.cliente_id}`)
						.then( data => {			
							loader.hide()						
							paymentMethods.value = data.data.paymentMethods			
							showPayment.value = true
						})
						.catch( error => {
							loader.hide()
							showPayment.value = true
							console.log(error)
						})
					} else {
						showPayment.value = true
					}
				}

				function paymentHandler(success) {
					pedido.value.pagado = true

					if (success) {						
						utils.updatePedido(pedido.value.id, {aprobado: true})
						.then( () => {											
							
						})						
						.catch( e => {
							console.log(e)
						})
					}

					showPayment.value = !showPayment.value
				}

				return {      
					pedido,
					cliente,
					cancelarPedido,
					puedeCancelar,
					route,
					router,
					utils,
					evidencias,
					showPayment,
					showPaymentModal,
					paymentHandler,
					paymentMethods
				}
			},
		}
	</script> 