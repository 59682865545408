<template>
	<div class="h-full overflow-y-scroll">
		<div class="block lg:flex ldg:flex-row items-center justify-between pt-4"> 
			<h3 class="text-2xl font-bold px-4">      		
				Inbox
			</h3>

			<div v-if="proveedor.payments" class="flex-none lg:flex content-center">
				<div class="grow lg:flex-none text-left px-4 mt-4 lg:mt-0 w-full lg:w-auto">				
					<div class="border border-primary rounded-full overflow-hidden flex items-center flex flex-row flex-grow text-center cursor-pointer bg-white w-full lg:w-auto">
						<div @click="inbox = true" :class="[{'bg-primary hover:bg-primary-dark text-white': inbox === true, 'text-primary': inbox === false}]" class="whitespace-nowrap font-bold py-0.5 px-4  w-1/2 lg:w-auto">
							Pendiente
						</div>
						<div @click="inbox = false" :class="[{'bg-primary hover:bg-primary-dark text-white': inbox === false, 'text-primary': inbox === true}]" class=" whitespace-nowrap font-bold py-0.5 px-4  w-1/2 lg:w-auto">
							Cobranza
						</div>	
					</div>										
				</div>
			</div>
		</div>

		
		<div v-if="pedidos && ordenesPendientes && ordenesPendientes.length > 0" class="pb-4"> 
			<inbox class="mt-4" :user="'proveedor'" :ordenes="ordenesPendientes" :fields="{fuente: proveedor.ecom, pago: (proveedor.payments ? true : false), cliente: true, surtir: (proveedor.standalone ? false : true), aprobar: (proveedor.standalone ? false : true), facturar: true, entregar: true}"></inbox>
		</div>
		<div v-if="pedidos && ordenesPendientes && ordenesPendientes.length == 0" class="pb-4"> 		
			<empty :texto="'¡No tienes ordenes pendientes!'"></empty>
		</div>
	</div>
</template>

<script>	
	import { useRoute } from 'vue-router'
	import { onMounted, ref, computed } from "vue"	
	import { utils } from './utils/utils.js'
	import { useLoading } from 'vue-loading-overlay'

	export default {
		name: 'Order',		
		setup() {
			const route = useRoute()			
			const proveedor = ref({})  				
			const pedidos = ref(null)								

			const inbox = ref(true)

			const loading = useLoading({
				color: "#428b74",
				loader: "spinner"
			});


			onMounted( async () => {	
				utils.getProveedor(route.params.proveedor_id)
				.then( data => {					
					proveedor.value = data
				})

				let loader = loading.show()
				utils.getPedidosPorProveedor(route.params.proveedor_id)
				.then( data => {
					loader.hide()
					pedidos.value = data					
				})
			})

			const ordenesPendientes = computed( () => {		
				if (inbox.value == true) {
					if (pedidos.value == null) {
						return []
					}	

					if (proveedor.value.standalone) {
						return pedidos.value.filter( pedido => {					
							if (pedido.cancelado == true) {
								return false
							} else if (pedido.require_factura && pedido.pedido_factura.length == 0) {
								return true
							}  else if (pedido.require_entrega && !pedido.entregado) {
								return true
							} else {
								return false
							}
						})
					} else {
						return pedidos.value.filter( pedido => {					
							if (pedido.cancelado == true) {
								return false
							} else if (pedido.require_aprobacion && ! pedido.aprobado) {
								return true
							} else if (pedido.require_factura && pedido.pedido_factura.length == 0) {
								return true
							}  else if (pedido.require_entrega && pedido.envios.filter(e => e.status == 'exitoso').length == 0) {
								return true
							} else {
								return false
							}
						})
					}	
				} else {
					return pedidos.value.filter( pedido => pedido.require_factura && !pedido.pagado )
				}
				
			})
			
			return {      
				proveedor,
				pedidos,				
				ordenesPendientes,
				inbox
			}
		},
	}
</script> 